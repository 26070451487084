<template>
  <section  @click="stopShow($event)" id="newSelect" class="multipleSelectFather">
    <section :style="{'max-width': width ? width + '!important' : '','min-width': width ? width + '!important' : ''}" class="multipleSelect">
      <section :style="{'background': newDisabled ? '#eee' : '','cursor':newDisabled? ' no-drop': 'pointer'}" :title="placeholder" @click="showDropFun" v-if="showSelectValue === ''" style="color: #ccc;font-size: 13.3px" class="multipleSelectShowPlace">{{placeholder}}</section>
      <section :style="{'background': newDisabled ? '#eee' : '','cursor':newDisabled? ' no-drop': 'pointer'}" :title="showSelectValue" v-else @click="showDropFun" class="multipleSelectShowPlace" style="font-size: 13.3px">{{showSelectValue}}</section>
      <i @click="showDropFun" class="icon iconfont iconxiala1 multipleSelectArror colorRed"></i>
      <section :id="randomId" class="multipleSelectDrop">
        <nav><span class="NoWrap" title="部门">部门</span><span class="NoWrap" title="部门助理">部门助理</span></nav>
        <div style="overflow: auto;max-height: 120px">
          <section @click="confirmData(item)" :class="item.checked ? 'active' : ''" v-html="returnShowValue(item)" class="multipleSelectSelectData" :key="index" v-for="(item, index) in selectData" style="font-size: 13.3px"></section>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
let array = []
let matchArray = []
export default {
  name: 'newSelect',
  model: {
    prop: 'selectValue',
    event: 'selectValue'
  },
  props: [
    'multiple',
    'selectValue',
    'chooseData',
    'needReturn',
    'showValue',
    'matchValue',
    'placeholder',
    'width',
    'disabled'
  ],
  data () {
    return {
      newDisabled: this.disabled,
      randomId: 'selectId' + new Date().getTime() + Math.random() * 1000,
      newSelectValue: this.selectValue,
      showSelectValue: '',
      selectData: this.chooseData
    }
  },
  created () { // vue 页面的生命周期 当我一进来页面之后 我先执行
    array = []
    matchArray = []
    this.handleData()
  },
  mounted () { // vue 页面的生命周期 当我进来之后 且加载完资源了之后再执行
    document.getElementById('index').addEventListener('click', closetab)
  },
  unmounted () {
    document.getElementById('index').removeEventListener('click', closetab)
  },
  methods: {
    handleData () {
      this.showSelectValue = ''
      let splitValue = (this.newSelectValue === '' || this.newSelectValue === null || this.newSelectValue === undefined) ? [''] : this.newSelectValue.toString().split(',')
      for (let i = 0; i < this.selectData.length; i++) {
        this.$set(this.selectData[i], 'checked', false)
        for (let j = 0; j < splitValue.length; j++) {
          if (this.selectData[i][this.needReturn].toString() === splitValue[j]) {
            // if (this.multiple) {
            //   this.selectData[i].checked = true
            // }
            this.selectData[i].checked = true
            let newHtml = this.matchValue
            for (let s = 0; s < matchArray.length; s++) {
              newHtml = newHtml.replace('{{' + matchArray[s] + '}}', this.selectData[i][matchArray[s]])
            }
            this.showSelectValue += newHtml + ','
          }
        }
      }
      this.showSelectValue = this.showSelectValue.substring(0, this.showSelectValue.length - 1)
      let html = this.showValue
      let html2 = this.matchValue
      matchArray = []
      getItem(html)
      getMatchItem(html2)
    },
    confirmData (item) {
      if (this.multiple) {
        let splitValue = (this.newSelectValue === '' || this.newSelectValue === null || this.newSelectValue === undefined) ? [''] : this.newSelectValue.split(',')
        let splitshowSelectValue = this.showSelectValue === '' ? [] : this.showSelectValue.split(',')
        if (splitValue.indexOf(item[this.needReturn].toString()) >= 0) {
          splitValue.splice(splitValue.indexOf(item[this.needReturn].toString()), 1)
          splitshowSelectValue.splice(splitshowSelectValue.indexOf(item[this.needReturn].toString()), 1)
          let selectHmtl = ''
          let selectHmtl2 = ''
          for (let i = 0; i < splitValue.length; i++) {
            selectHmtl += splitValue[i] + ','
            selectHmtl2 += splitshowSelectValue[i] + ','
          }
          selectHmtl = selectHmtl.substring(0, selectHmtl.length - 1)
          selectHmtl2 = selectHmtl2.substring(0, selectHmtl2.length - 1)
          this.newSelectValue = selectHmtl
          this.showSelectValue = selectHmtl2
          this.$emit('selectValue', this.newSelectValue)
          this.$emit('on-change', '')
        } else {
          if (this.newSelectValue === '') {
            this.newSelectValue += item[this.needReturn]
          } else {
            this.newSelectValue += ',' + item[this.needReturn]
          }
          this.$emit('selectValue', this.newSelectValue)
          this.$emit('on-change', '')
          if (this.showSelectValue === '') {
            this.showSelectValue += item[this.matchValue]
          } else {
            this.showSelectValue += ',' + item[this.matchValue]
          }
        }
        this.handleData()
      } else {
        this.newSelectValue = item[this.needReturn]
        let html = this.matchValue
        for (let i = 0; i < matchArray.length; i++) {
          html = html.replace('{{' + matchArray[i] + '}}', item[matchArray[i]])
        }
        this.showSelectValue = html
        this.$emit('selectValue', this.newSelectValue)
        this.$emit('on-change', '')
        let dom = document.getElementById(this.randomId)
        dom.style.opacity = '0'
        setTimeout(() => {
          dom.style.display = 'none'
        }, 200)
      }
    },
    showDropFun () {
      if (!this.newDisabled) {
        let dom = document.getElementById(this.randomId)
        let domList = document.querySelectorAll('.multipleSelectFather')
        for (let i = 0; i < domList.length; i++) {
          domList[i].style.zIndex = 1
        }
        dom.parentNode.parentNode.style.zIndex = 2
        if (dom.style.display === 'none' || dom.style.display === '') {
          dom.style.display = 'block'
          setTimeout(() => {
            dom.style.opacity = '1'
          }, 10)
        } else {
          dom.style.opacity = '0'
          setTimeout(() => {
            dom.style.display = 'none'
          }, 200)
        }
      }
    },
    returnShowValue (object) {
      let html = this.showValue
      for (let i = 0; i < array.length; i++) {
        html = html.replace('{{' + array[i] + '}}', object[array[i]])
      }
      return html
    },
    stopShow (e) {
      e.stopPropagation()
    }
  },
  watch: {
    selectValue (value) {
      this.newSelectValue = value
      this.showSelectValue = ''
      this.handleData()
      console.log(value)
    },
    chooseData (value) {
      this.selectData = value
      this.handleData()
    },
    disabled (value) {
      this.newDisabled = value
    }
  },
  components: {
  }
}
function closetab () {
  let canderList = document.querySelectorAll('.multipleSelectDrop')
  if (canderList) {
    for (let i = 0; i < canderList.length; i++) {
      canderList[i].style.opacity = '0'
    }
    setTimeout(() => {
      for (let i = 0; i < canderList.length; i++) {
        canderList[i].style.display = 'none'
      }
    }, 200)
  }
}
function getItem (html) {
  if (html.indexOf('{{') >= 0) {
    let aPos = html.indexOf('{{')
    let bPos = html.indexOf('}}')
    let newName = html.substr(aPos + 2, bPos - aPos - 2)
    array.push(newName)
    html = html.replace('{{', '').replace('}}', '')
    getItem(html)
  }
}
function getMatchItem (html) {
  if (html.indexOf('{{') >= 0) {
    let aPos = html.indexOf('{{')
    let bPos = html.indexOf('}}')
    let newName = html.substr(aPos + 2, bPos - aPos - 2)
    matchArray.push(newName)
    html = html.replace('{{', '').replace('}}', '')
    getMatchItem(html)
  }
}
</script>

<style scoped>
  .multipleSelectFather{
    position: relative;
  }
  .multipleSelect{
    position: relative;
    max-width: 380px;
    min-width: 180px;
    width: 100%;
    text-align: left;
    z-index: 5;
  }
  .multipleSelect .multipleSelectShowPlace {
    background-color: #fff;
    padding: 6px 20px 6px 10px;
    border-radius: 4px;
    border:1px solid #A1A1A1;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .multipleSelect .multipleSelectDrop {
    position: absolute;
    top: 32px;
    background: #fff;
    width: 100%;
    display: none;
    opacity: 0;
    transition: opacity 0.2s;
    max-height: 150px;
    /*overflow: auto;*/
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 4px;
  }
  .multipleSelect .multipleSelectDrop > nav {
    background-color: #FDE3E9;
    color: #77253A;
  }
  .multipleSelect .multipleSelectDrop > nav span {
    display: inline-block;
    padding: 6px 10px;
    width: calc(50% - 20px);
  }
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
    position: relative;
  }
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData:nth-child(2n) {
    background-color: #FAFAFA;
  }
  /*.multipleSelect .multipleSelectDrop .multipleSelectSelectData.active::after{*/
  /*  content: '√';*/
  /*  font-family: "微软雅黑", "Arial";*/
  /*  position: absolute;*/
  /*  right: 5px;*/
  /*}*/
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData.active {
    color: #C50F3C;
  }
  .multipleSelect .multipleSelectDrop .multipleSelectSelectData:hover{
    /*color: #fff;*/
    cursor: pointer;
    background: #f1f1f1;
  }
  .multipleSelectArror{
    position: absolute;
    right: 8px;
    top: 9px;
    color: #666;
    font-size: 10px;
  }
  @media screen and (max-width: 1370px) {
    .multipleSelect {
      max-width: 200px!important;
      min-width: 200px!important;
    }
  }
</style>

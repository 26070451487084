
var signalR = (function () {
  let recordCode = 0x1e;
  let recordString = String.fromCharCode(recordCode);
  let isConnectioned = false;
  let ws = {}
  console.log(isConnectioned)
  let _events = new Array();
  //初始化相关事件
  //消息发送事件
  _events['send'] = function (obj) {
      console.log(obj)
  };
  //消息接收事件
  _events['ReceiveMessage'] = function (message) {
      console.log(message)
  };
  //连接事件
  _events['connection'] = function () {
  };
  //连接关闭事件
  _events['close'] = function () {
  };
  //连接异常处理事件
  _events['error'] = function (ex) {
      console.log(ex)
  };
  return {
      //事件绑定
      on: function (eventName, eventMethod) {
          if (_events[eventName] != null && _events[eventName] != undefined) {
              _events[eventName] = eventMethod;
          }
      },
      //连接方法
      connection: function (url) {
          let self = this;
          ws = new WebSocket(url)
          ws.onopen = function () {
              let handshakeRequest = {
                  protocol: 'json',
                  version: 1
              };
              let senddata = `${JSON.stringify(handshakeRequest)}${recordString}`;
              self.isConnectioned = true;
              ws.send(senddata);
              _events['connection']();
          };
          ws.onclose = function () {
              self.isConnectioned = false;
              _events['close']();
          };
          //接收到消息
          ws.onmessage = function (res) {
              try {
                  let obj = JSON.parse(decodeURIComponent(res.data).substring(0, decodeURIComponent(res.data).indexOf('\x1E')));
                  //当收到返回消息type=1（调用方法）
                  if (obj.type == 1) {
                      _events['ReceiveMessage'](obj);
                  }
                  // eslint-disable-next-line no-empty
              } catch (ex) {
                  console.log(ex)
              }
          };
          ws.onerror = function (ex) {
              self.isConnectioned = false;
              _events['error'](ex);
          };
      },
      abortConnection: function () {
          try {
              ws.close()
          } catch (e) {
              console.log(e)
          }
      },
      sendMessage: function (data, target) {
          let self = this;
          if (!self.isConnectioned) {
              _events['error']('未连接');
              return;
          }
          // let args = new Array();
          // args.push(data);
          let body = {
              arguments: data,   //SignalR服务端接收时必须为数组参数
              target: target || 'sendPicture',        //SignalR端方法
              type: 1,
              invocationId: "0",
              Headers:null
          };
          //发送的数据，分隔符结尾：
          let senddata = `${JSON.stringify(body)}${recordString}`;
          ws.send(senddata);
          _events['send'](senddata);
      }
  }
});
module.exports = {
  signalR: signalR
}

<template>
  <div id="scrollPlace" style="background: #fff;min-height: 100vh;">

    <div id="topTab" class="topTab">
      <div>
        <div @click="changeType(1)" :class="tab === 1 ? 'active' : ''">人员</div>
        <div @click="changeType(2)" :class="tab === 2 ? 'active' : ''">资料</div>
      </div>
    </div>
    <div v-if="tab === 2 && documentInfo" id="ziliaoPlace" class="ziliaoPlace">
      <div>
        <div>
          <img :src="documentInfo.imgUrl" />
        </div>
        <div>
          <div class="p_10">
            {{documentInfo.Title}}
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 1" style="padding-top: 100px">
      <div v-if="+myUser.Type === 1" class="p_r t_c">
        <div class="callBack1">
          <img class="w100" :src="defalutImg1" />
          <div class="card">医生</div>
          <div style="bottom: -18px" class="nickName">{{myUser.NickName}}</div>
        </div>
        <img class="callBack" src="../../assets/img/icon/callRole.png" />
        <div class="callBack2">
          <img class="w100" :src="defalutImg2" />
          <div class="card">代表</div>
          <div style="bottom: -18px" class="nickName">
            <div>
              {{otherUser.NickName}}
            </div>
          </div>
          <div v-if="hasEnter" class="nickName">
            <div  class="c_999">
              对方已加入会议
            </div>
          </div>
          <div v-if="!hasEnter && otherUser.NickName" class="nickName">
            <div  class="c_999">
              对方尚未加入
            </div>
          </div>
        </div>
      </div>
      <div v-if="+myUser.Type !== 1" class="p_r t_c">
        <div class="callBack1">
          <img class="w100" :src="defalutImg1" />
          <div class="card">医生</div>
          <div style="bottom: -18px" class="nickName">
            <div>
              {{otherUser.NickName}}
            </div>
          </div>
          <div v-if="hasEnter" class="nickName">
            <div  class="c_999">
              对方已加入会议
            </div>
          </div>
          <div v-if="!hasEnter && otherUser.NickName" class="nickName">
            <div  class="c_999">
              对方尚未加入
            </div>
          </div>
        </div>
        <img class="callBack" src="../../assets/img/icon/callRole.png" />
        <div class="callBack2">
          <img class="w100" :src="defalutImg2" />
          <div class="card">代表</div>
          <div style="bottom: -18px" class="nickName">{{myUser.NickName}}</div>
        </div>
      </div>
      <div v-if="+role === 2" style="margin-top: 100px" class="t_c m_t w90">
        <button @click="openonetoone" :class="(myUser.OpenId && otherUser.OpenId) || !isPublished ? '' : 'disabledButton'" class="allBigButton">
          <img style="width: 20px" class=" m_l_r_10 d_l v_m" src="../../assets/img/icon/videoIcon.png" />
          <text class="d_l m_l_r_10 v_m">{{isPublished ? '已在会议中' : '进入会议'}}</text>
        </button>
      </div>
      <div v-else style="margin-top: 100px" class="t_c m_t w90">
        <button @click="openonetoone" :class="(myUser.OpenId && otherUser.OpenId) || !isPublished ? '' : 'disabledButton'" class="allBigButton">
          <img style="width: 20px" class=" m_l_r_10 d_l v_m" src="../../assets/img/icon/videoIcon.png" />
          <text class="d_l m_l_r_10 v_m">{{isPublished ? '已在会议中' : '进入会议'}}</text>
        </button>
      </div>
    </div>

    <div v-if="tab === 2" class="bottomZiliaoNew">
      <div>
        <div class="split_6_m2 w100">
          <div @click="openPermissionFun">
            <div class="p_r">
              <img style="margin-bottom: 6px" v-if="openPermission === role" class="  d_l v_m" src="../../assets/img/icon/baifang5_white.png" />
              <img style="margin-bottom: 6px" v-if="openPermission !== role" class="  d_l v_m" src="../../assets/img/icon/baifang5.png" />
              <div v-if="openPermission === role" class="newGreenTip">
                <img style="width: 100%" class="  d_l v_m" src="../../assets/img/icon/jihuo.png" />
              </div>
            </div>
            <div class="m_t_6">主持人</div>
          </div>
          <div @click="openDataFun">
            <div class="p_r">
              <img v-if="openData && openPermission === role" class="  d_l v_m" src="../../assets/img/icon/baifang1_white.png" />
              <img v-else class="  d_l v_m" src="../../assets/img/icon/baifang1.png" />
              <div v-if="openData && openPermission === role" class="newGreenTip">
                <img style="width: 100%" class="  d_l v_m" src="../../assets/img/icon/jihuo.png" />
              </div>
            </div>
            <div>投屏</div>
          </div>
<!--          <div @click="openRest">-->
<!--            <div>-->
<!--              <img v-if="!rest" class="  d_l v_m" src="../../assets/img/icon/baifang2.png" />-->
<!--              <img v-if="rest" class="  d_l v_m" src="../../assets/img/icon/baifang2_white.png" />-->
<!--            </div>-->
<!--            <div>茶歇</div>-->
<!--          </div>-->
          <div @click="openCanvas">
            <div class="p_r">
              <img v-if="open" class="  d_l v_m" src="../../assets/img/icon/baifang3_white.png" />
              <img v-if="!open" class="  d_l v_m" src="../../assets/img/icon/baifang3.png" />
              <div v-if="open" class="newGreenTip">
                <img style="width: 100%" class="  d_l v_m" src="../../assets/img/icon/jihuo.png" />
              </div>
            </div>
            <div>标记</div>
          </div>
          <div @click="clearCanvas">
            <div class="p_r">
              <img v-if="openPermission === role && open" class="  d_l v_m" src="../../assets/img/icon/baifang4_white.png" />
              <img v-else class="  d_l v_m" src="../../assets/img/icon/baifang4.png" />
              <div v-if="open && openPermission === role" class="newGreenTip">
                <img style="width: 100%" class="  d_l v_m" src="../../assets/img/icon/jihuo.png" />
              </div>
            </div>
            <div>清除</div>
          </div>
          <div style="width: 35%">
            <div style="display: flex;align-items: center;">
              <div>
                <img v-if="nowPage === 1 || nowPage === 0" style="width: 15px;margin-bottom: 0" class=" m_l_r_10" src="../../assets/img/icon/baifang6.png" />
                <img @click="prevDocument" v-else style="width: 15px;margin-bottom: 0" class=" m_l_r_10" src="../../assets/img/icon/baifang6_white.png" />
              </div>
              <div style="font-size:10px;wdith: calc(100% - 30px);background: #444;border-radius: 2px;padding: 2px">
                <input class="t_c" style="border: none;background: transparent;width: 16px" @blur="changePage" id="pageInfo" @focus="selectionAll" :readonly="nowPage === 0" v-model="nowPage" type="text"><span>/</span>
                <input class="t_c" style="border: none;background: transparent;width: 16px" :readonly="true" :value="documentInfo.TotalPage || 0" type="text">
              </div>
              <div>
                <img v-if="nowPage === documentInfo.TotalPage || !documentInfo.TotalPage" style="width: 15px;margin-bottom: 0" class=" m_l_r_10 " src="../../assets/img/icon/baifang7.png" />
                <img @click="nextDocument" v-else style="width: 15px;margin-bottom: 0" class=" m_l_r_10" src="../../assets/img/icon/baifang7_white.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w100" @click="openSuoFang">
          <div style="width: 80%;margin-left: 15%" class="p_r">
            <img v-if="suofang" style="width: 60%" class="  d_l v_m" src="../../assets/img/icon/suofang_white.png" />
            <img v-if="!suofang" style="width: 60%" class="  d_l v_m" src="../../assets/img/icon/suofang.png" />
            <div v-if="suofang" class="newGreenTip">
              <img style="width: 100%;left: 0;top: 0" class=" p_a d_l v_m" src="../../assets/img/icon/jihuo.png" />
            </div>
          </div>
          <div>缩放</div>
        </div>
      </div>
    </div>

    <div :style="{'visibility': tab === 1 || openData || rest ? 'hidden' : ''}" style="display: inline-block;width: 100%;vertical-align: top;position: absolute;top:0;padding-top:200px">
      <div class="noticeCenter">
        <div>
          <img id="newimage2" src="../../assets/img/icon/noShare.png" style="width: 20%;user-select: none" />
          <div class="ft_16 c_999 m_t_40 m_b_40">尚未投屏</div>
          <div class="ft_12 c_999">您可以点击下方"投屏"切换当前会议状态</div>
        </div>
      </div>
    </div>

    <div :style="{'visibility': tab === 1  || !rest ? 'hidden' : ''}" style="display: inline-block;width: 100%;vertical-align: top;position: absolute;top:0;padding-top:200px">
      <div class="noticeCenter">
        <div>
          <img id="newimage3" src="../../assets/img/icon/rest.png" style="width: 20%;usezr-select: none" />
          <div class="ft_16 c_999 m_t_40 m_b_40">会议茶歇</div>
          <div class="ft_12 c_999">休息一下，马上继续</div>
        </div>
      </div>
    </div>

    <div id="suoFangImgDiv" v-show="suofang" style="user-select: none;box-shadow: 0 0 10px 5px #eee;position: fixed;left: 0;top: 0;bottom:0;right:0;display:block;z-index: 99;overflow: auto;background: #fff">
      <div class="p_r">
        <img id="suoFangImg" :style="{top: suofangTop + 'px', left: suofangLeft + 'px', transform: 'scale(' + suofangRate + ')'}" @load="startSuoFang($event)" style="width: 100%;transform-origin:left top;padding-bottom: 200px;padding-top: 200px" class="p_r" :src="imgUrl" alt="">
        <canvas id="suoCanvas" :style="{top: suofangTop + 'px', left: suofangLeft + 'px', transform: 'scale(' + suofangRate + ')'}" style="width: 100%;height: calc(100% - 400px);transform-origin:left top;padding-bottom: 200px;padding-top: 200px;pointer-events: none" class="p_a"></canvas>
      </div>
    </div>
    <div :style="{'display': tab === 1 || !openData || rest ? 'none' : 'inline-block'}" style="width: 100%;vertical-align: top;position: absolute;top:0;padding-top:200px;padding-bottom:200px;background: #fff" id="appWeb">
      <img @load="imageLoad($event)" id="newimage" :src="imgUrl" style="width: 100%;user-select: none;box-shadow: 0 0 10px 5px #eee" />
      <canvas type="2d" id="setCanvas" style="width: 100%;height: calc(100% - 400px);position: absolute;left: 0;top: 200px;display:block;"></canvas>
    </div>

    <div :style="{'z-index': isPublished ? '' : '-9999'}" :class="!miniSize ? '' : 'moveDis'" id="videoPlace">
      <div v-show="!miniSize" @click="closeClient" class="closeC">
        <img src="../../assets/img/endPhone.png" alt="">
      </div>
      <div v-show="!miniSize" @click="closeVFun" :class="isMutedVideo ? 'disabled' : ''" class="closeV">
        <img src="../../assets/img/unVideo.png" alt="">
      </div>
      <div v-show="!miniSize" @click="closeAFun" :class="isMutedAudio ? 'disabled' : ''"  class="closeA">
        <img src="../../assets/img/unAudio.png" alt="">
      </div>
      <img v-show="!miniSize" @click="showMiniSize" class="miniSize" style="width: 30px;" src="../../assets/img/miniSize.png" alt="">
      <img v-show="!miniSize" v-if="cameraList.length > 1" @click="switchDeviceFun" class="miniSize" style="width: 30px;left: 50px" src="../../assets/img/switchDevice.png" alt="">
      <div @click="() => {
        if (!switchMainSec) {
          switchMainSec = !switchMainSec
        }
        if (miniSize && switchMainSec) {
          miniSize = false
          unTrans()
        }
      }" :class="{mainVideo: switchMainSec, secVideo: !switchMainSec, smallSize: miniSize && switchMainSec, noneSize: miniSize && !switchMainSec}" id="localStream"></div>
      <div @click="() => {
        if (switchMainSec) {
          switchMainSec = !switchMainSec
        }
        if (miniSize && !switchMainSec) {
          miniSize = false
          unTrans()
        }
      }" :class="{mainVideo: !switchMainSec, secVideo: switchMainSec, smallSize: miniSize && !switchMainSec, noneSize: miniSize && switchMainSec}" id="otherStream"></div>
    </div>
    <div v-show="isPublished && miniSize" style="bottom: 20px;text-align: left" class="bottomZiliao">
      <div style="width: 100%">
        <div style="height: 100%;" class="split_7_3 w100">
          <div style="height: 100%;">
            <img @click="miniSize = false;unTrans()" class="d_l v_t" style="height: 100%;width: auto;margin-left: 10px;visibility: hidden" :src="(role === 1 ? defalutImg1 : defalutImg2)" />
            <div @click="miniSize = false;unTrans()" class="d_l m_l_r_10 v_t">
              <div>
                {{myUser.NickName}}
              </div>
              <div style="color: #ccc" class="m_t_10">
                通话中 {{formatSeconds()}}
              </div>
            </div>
          </div>
          <div style="height: 100%;">
            <img @click="closeClient" style="height: 60%;width: auto;margin-right: 10px;margin-bottom: 2px" src="../../assets/img/phone.png" alt="">
            <div @click="closeClient" style="margin-right: 12px;">
              挂 断
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, toRefs, reactive, getCurrentInstance, onMounted, onUnmounted, nextTick} from "vue"
import { useRouter, useRoute } from "vue-router"
import signalR from '../../assets/js/signalR';
import TRTC from 'trtc-js-sdk';
import { isUndefined } from '@/utils/utils.js';
let _signalR = new signalR.signalR();
export default defineComponent( {
  name: 'HelloWorld',
  setup(props, ctx) {
    console.log(props)
    console.log(ctx)
    const router = useRouter()
    const route = useRoute()
    const { proxy } = getCurrentInstance()
    console.log(router)
    console.log(proxy)
    onMounted(() => {
      try {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(async () => {
          myData.cameraList = await TRTC.getCameras();
          myData.microphoneList = await TRTC.getMicrophones();
        });
      } catch (e) {
        console.log(e)
      }
      if (route.query.role) {
        myData.role = +route.query.role
      } else {
        myData.role = 2
      }
      if (localStorage.getItem('meetingCode')) {
        const url = proxy.$commonJs.returnQuery('/api/Meeting/info', {meetingSn: localStorage.getItem('meetingCode')})
        proxy.$axios.get(url).then(p => {
          myData.meetingInfo = p.data
        })
      }
      myData.canvasArray = {}
      if (!localStorage.getItem('userInfo')) {
        let userInfo = {
          name: new Date().getTime() + '_' +(Math.random() * 9999),
          color: +myData.role === 1 ? 'rgb(252, 198, 12)' : 'rgb(6, 151, 186)'
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        myData.myUserInfo.userInfo = userInfo
      } else {
        myData.myUserInfo.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      }
      myData.myUserInfo.deviceInfo = myData.percentArray
      myData.myUserInfo.dotArray = []
      // 发送消息
      _signalR.on("connection", function () {
        _signalR.sendMessage([localStorage.getItem('meetingCode'), localStorage.getItem('openId'), JSON.parse(localStorage.getItem('myInfo')).avatarUrl , JSON.parse(localStorage.getItem('myInfo')).nickName, myData.role], 'JoinMeeting');
        //消息格式
        myData.inter = setInterval(() => {
          const websocketData = {
            data: myData.myUserInfo,
            pageNo: myData.nowPage
          }
          _signalR.sendMessage([JSON.stringify(websocketData), myData.ConnectionId, myData.nowPage]);
        }, 500)
      });
      // 发送消息
      _signalR.on("error", function () {
        clearInterval(myData.inter)
        clearInterval(myData.inter2)
        myData.hasEnter = false
        if (!myData.showLeave) {
          myData.showLeave = true
          proxy.$commonJs.doubleComfirm({
            content: '检测到您已断开连接，请关闭网页后重新进入会议',
            successFun: () => {
              router.back(-1)
            },
            errorFun: () => {
              router.back(-1)
            }
          })
        }
      });
      _signalR.on("ReceiveMessage", function (e) {
        if (e) {
          if (e.target === 'sendPicture') {
            const newData = JSON.parse(e.arguments[0])
            if (newData.data.userInfo.name) {
              if (newData.data.userInfo.name !== myData.myUserInfo.userInfo.name) {
                myData.canvasArray[newData.data.userInfo.name] = newData
              }
            }
          }
          if (e.target === 'ConnectionId') {
            myData.ConnectionId = e.arguments[0]
          }
          if (e.target === 'common') {
            const newData = e.arguments[0]
            if (newData) {
              if (newData === 'True') {
                myData.rest = true
              } else if (newData === 'False') {
                myData.rest = false
              } else if (newData.indexOf('hasEnter') >= 0) {
                const role = newData.split('_')[1]
                if (+role !== +myData.role) {
                  myData.hasEnter = true
                }
              } else if (newData.indexOf('scorll') >= 0) {
                const clientHeight = +newData.split('_')[3]
                // const clientWidth = newData.split('_')[4]
                let scrollHeight = document.body.scrollHeight
                if (document.documentElement && document.documentElement.scrollHeight > document.body.scrollHeight) {
                  scrollHeight = document.documentElement.scrollHeight
                }
                const nowHeight = scrollHeight - window.screen.height
                // const nowWidth = window.screen.width
                const scorll = newData.split('_')[2]
                const role = newData.split('_')[1]
                if (+role !== +myData.role) {
                  if (document.body) {
                    document.body.scrollTop = scorll * (nowHeight / clientHeight)
                  }
                  if (document.documentElement) {
                    document.documentElement.scrollTop = scorll * (nowHeight / clientHeight)
                  }
                }
              } else {
                myData.openPermission = +newData
              }
            }
          }
          if (e.target === 'startShare' || e.target === 'stopShare') {
            if (e.arguments[0]) {
              const newData = JSON.parse(e.arguments[0])
              if (newData) {
                newData.imgUrl = newData.Cover
                myData.nowPage = 1
                myData.documentInfo = newData
              } else {
                myData.nowPage = 0
              }
            }
            myData.openData = e.target === 'startShare'
          }
          if (e.target === 'pageInfo') {
            const newData = JSON.parse(e.arguments[0])
            myData.imgUrl = newData.Url
            myData.nowPage = newData.Page
            myData.imgKey = newData.Key
            console.log(myData.imgKey)
            for (let i in myData.canvasArray) {
              if (myData.canvasArray[i].data) {
                myData.canvasArray[i].data.dotArray = []
              }
            }
            // setTimeout(() => {
            //   document.getElementById('newimage').onload = (e) => {
            //     imageLoad(e)
            //   }
            // }, 100)
          }
          if (e.target === 'RoomMember') {
            const url = proxy.$commonJs.returnQuery('/api/Auth/ConnectionId', {
              meetingcode: localStorage.getItem('meetingCode'),
              role: myData.role
            })
            proxy.$axios.get(url).then(p => {
              myData.ConnectionId = p.data
            })
            if (myData.myUser.OpenId && myData.otherUser.OpenId) {
              myData.openPermission = 0
              myData.open = false
              _signalR.sendMessage([myData.openPermission], 'Common');
              for (let i in myData.canvasArray) {
                if (myData.canvasArray[i].data) {
                  myData.canvasArray[i].data.dotArray = []
                }
              }
              myData.hasEnter = false
              proxy.$commonJs.doubleComfirm({
                content: '对方已离开房间，是否等待?',
                successFun: () => {
                },
                errorFun: () => {
                  router.back(-1)
                }
              })
            }
            myData.otherUser = {}
            myData.myUser = {}
            const newData = JSON.parse(e.arguments[0])
            for (let i = 0; i < newData.length; i++) {
              if (newData[i].OpenId === localStorage.getItem('openId')) {
                myData.myUser = newData[i]
                // todo 允许开启视频
              } else {
                myData.otherUser = newData[i]
              }
            }
          }
        }
      });
      _signalR.connection('wss://onlineactivityapi-q.bayer.cn/siganlR');//服务端链接
      document.getElementById('setCanvas').ontouchstart = (e) => {
      // || myData.openPermission !== myData.role
        if (myData.open || myData.openPermission !== myData.role) {
          e.preventDefault()
        }
        ontouchstart(e)
      }
      document.getElementById('setCanvas').ontouchmove = (e) => {
      // || myData.openPermission !== myData.role
        if (myData.open || myData.openPermission !== myData.role) {
          e.preventDefault()
        }
        ontouchmove(e)
      }
      document.getElementById('setCanvas').ontouchend = (e) => {
      // || myData.openPermission !== myData.role
        if (myData.open || myData.openPermission !== myData.role) {
          e.preventDefault()
        }
        ontouchstart(e)
      }
    })
    onUnmounted(async () => {
      clearInterval(myData.inter)
      clearInterval(myData.inter2)
      _signalR.abortConnection()
      await leave();
    })
    const myData = reactive({
      inter: 0,
      originPage: 0,
      inter2: 0,
      cameraId: '',
      nowSec: 0,
      microphoneId: '',
      inviteUserSig: '',
      sdkAppId: JSON.parse(localStorage.getItem('myInfo')).appId,
      roomId: JSON.parse(localStorage.getItem('myInfo')).roomId,
      userSig: JSON.parse(localStorage.getItem('myInfo')).userSig,
      userId: JSON.parse(localStorage.getItem('myInfo')).userId,
      showLeave: false,
      switchMainSec: false,
      isHostMode: true,
      imgKey: '',
      miniSize: true,
      ready: false,
      nowCamera: 0,
      cameraList: [],
      microphoneList: [],
      nowPage: 0,
      defalutImg1: require('../../assets/img/defaultImage_yisheng.png'),
      defalutImg2: require('../../assets/img/defaultImage_daibiao.png'),
      openPermission: 2,
      isDoctor: false,
      documentInfo: '',
      overHeight: 0,
      otherUser: {},
      myUser: {},
      ConnectionId: 0,
      suofangTop: 0,
      suofangLeft: 0,
      suofangRate: 1,
      canvasImgUrl: '',
      imgUrl: '',
      inviteLink: '',
      meetingInfo: {},
      tab: 1,
      buttonName: '开始拜访',
      open: false,
      suofang: false,
      hasEnter: false,
      openData: false,
      canvasArray: {},
      percentArray: {
        width: 0,
        height: 0
      },
      moveDomData: {
        startX: 0,
        startY: 0,
        left: 0,
        top: 0
      },
      nowX: 0,
      startDistance: 0,
      nowY: 0,
      role: '',
      moveFlag: false,
      rest: false,
      overflow: false,
      canvas: {},
      ctx: {},
      myUserInfo: {
        userInfo: {},
        deviceInfo: {},
        dotArray: []
      }
    })
    const formatSeconds = () => {
      //  秒
      let second = parseInt(myData.nowSec)
      //  分
      let minute = 0
      //  小时
      let hour = 0
      //  天
      //  let day = 0
      //  如果秒数大于60，将秒数转换成整数
      if (second > 59) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 59) {
          //  获取小时，获取分钟除以60，得到整数小时
          hour = parseInt(minute / 60)
          //  获取小时后取佘的分，获取分钟除以60取佘的分
          minute = parseInt(minute % 60)
          //  如果小时大于24，将小时转换成天
          //  if (hour > 23) {
          //    //  获取天数，获取小时除以24，得到整天数
          //    day = parseInt(hour / 24)
          //    //  获取天数后取余的小时，获取小时除以24取余的小时
          //    hour = parseInt(hour % 24)
          //  }
        }
      }
      second = second < 10 ? '0' + second : second
      let result = '' + second
      if (minute > 0) {
        minute = minute < 10 ? '0' + minute : minute
        result = '' + minute + ':' + result
      } else {
        result = '00' + ':' + result
      }
      if (hour > 0) {
        hour = hour < 10 ? '0' + hour : hour
        result = '' + hour + ':' + result
      } else {
        result = '00' + ':' + result
      }
      //  if (day > 0) {
      //    result = '' + parseInt(day) + '天' + result
      //  }
      return result
    }
    const openPermissionFun = () => {
      if (myData.openPermission === 0 || myData.openPermission === myData.role) {
        myData.openPermission = myData.openPermission === 0 ? myData.role : 0
        if (myData.openPermission === 0) {
          myData.open = false
        }
        myData.suofang = false
        _signalR.sendMessage([myData.openPermission], 'Common');
      }
    }
    const changeType = (e) => {
      const type = e
      myData.tab = +type
      // if (+type === 2) {
      //   myData.overHeight = document.getElementById('appWeb').clientHeight - myData.percentArray.height
      //   console.log(document.getElementById('appWeb').clientHeight)
      //   console.log(myData.percentArray.height)
      // }
    }
    const nextDocument = () => {
      if (myData.openPermission !== myData.role || myData.rest) {
        return
      }
      myData.nowPage = myData.nowPage + 1
      for (let i in myData.canvasArray) {
        if (myData.canvasArray[i].data) {
          myData.canvasArray[i].data.dotArray = []
        }
      }
      _signalR.sendMessage([myData.nowPage], 'TurnPage');
    }
    const prevDocument = () => {
      if (myData.openPermission !== myData.role || myData.rest) {
        return
      }
      myData.nowPage = myData.nowPage - 1
      for (let i in myData.canvasArray) {
        if (myData.canvasArray[i].data) {
          myData.canvasArray[i].data.dotArray = []
        }
      }
      _signalR.sendMessage([myData.nowPage], 'TurnPage');
    }
    const selectionAll = () => {
      myData.originPage = myData.nowPage
      document.getElementById('pageInfo').select()
    }
    const changePage = () => {
      if (myData.openPermission !== myData.role || myData.rest) {
        return
      }
      if (isNaN(myData.nowPage)) {
        proxy.$commonJs.showTips('请输入有效页码值')
        myData.nowPage = myData.originPage
        return
      }
      if (myData.nowPage > myData.documentInfo.TotalPage) {
        proxy.$commonJs.showTips('请输入有效页码值')
        myData.nowPage = myData.originPage
        return;
      }
      myData.nowPage = parseInt(myData.nowPage)
      for (let i in myData.canvasArray) {
        if (myData.canvasArray[i].data) {
          myData.canvasArray[i].data.dotArray = []
        }
      }
      _signalR.sendMessage([parseInt(myData.nowPage)], 'TurnPage');
    }
    const startSuoFang = (e) => {
      console.log(e)
      startSuoFangFun()
    }
    const imageLoad = (e) => {
      let originalWidth = e.target.width;
      let originalHeight = e.target.height;
      // let height = 375 * originalHeight / originalWidth
      myData.canvas = document.getElementById('setCanvas')
      myData.ctx = myData.canvas.getContext('2d')
      const dpr = 2
      myData.canvas.width = originalWidth * dpr
      myData.canvas.height =  originalHeight * dpr
      myData.percentArray.width = originalWidth;
      myData.percentArray.height =  originalHeight
      myData.ctx.scale(dpr, dpr)
      // myData.overHeight = (document.getElementById('appWeb').clientHeight - myData.percentArray.height) / 2
      myData.overHeight = 200
      console.log(document.getElementById('appWeb').clientHeight)
      myData.inter2 = setInterval(() => {
        setCanvas()
        let scrollHeight = document.body.scrollHeight
        if (document.documentElement && document.documentElement.scrollHeight > document.body.scrollHeight) {
          scrollHeight = document.documentElement.scrollHeight
        }
        if (document.body) {
          if (document.body.scrollTop !== 0 && myData.openPermission === myData.role) {
            _signalR.sendMessage(['scorll_' + myData.role + '_' + document.body.scrollTop + '_' + (scrollHeight - window.screen.height) + '_' + window.screen.width], 'Common');
          }
        }
        if (document.documentElement) {
          if (document.documentElement.scrollTop !== 0 && myData.openPermission === myData.role) {
            _signalR.sendMessage(['scorll_' + myData.role + '_' + document.documentElement.scrollTop + '_' + (scrollHeight - window.screen.height) + '_' + window.screen.width], 'Common');
          }
        }
      }, 1000/60)
    }
    const setCanvas = () => {
      myData.ctx.clearRect(0,0,myData.canvas.width, myData.canvas.height)
      for (let i in myData.canvasArray) {
        if (myData.canvasArray[i].data) {
          myData.ctx.beginPath()
          myData.ctx.strokeStyle = myData.canvasArray[i].data.userInfo.color
          if (myData.canvasArray[i].data.dotArray[0]) {
            myData.ctx.moveTo(
                (myData.canvasArray[i].data.dotArray[0].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width)
                ,
                (myData.canvasArray[i].data.dotArray[0].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight)
            )
          }
          for (let j = 0; j < myData.canvasArray[i].data.dotArray.length; j++) {
            if (myData.canvasArray[i].data.dotArray[j - 1]) {
              if (Math.abs(myData.canvasArray[i].data.dotArray[j].x - myData.canvasArray[i].data.dotArray[j - 1].x) > 10 && Math.abs(myData.canvasArray[i].data.dotArray[j].y - myData.canvasArray[i].data.dotArray[j - 1].y) > 10) {
                myData.ctx.moveTo(
                    (myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width)
                    ,
                    // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
                    (myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight)
                )
              } else {
                myData.ctx.lineTo(
                    (myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width)
                    ,
                    // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
                    (myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight)
                )
              }
            } else {
              myData.ctx.lineTo(
                  (myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width)
                  ,
                  // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
                  (myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight)
              )
            }

            // myData.ctx.fillRect((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width), (myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight),(4 / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width),(4 / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
          }
          myData.ctx.stroke();
          myData.ctx.closePath()
          // myData.ctx.fill();
        }
      }
    }
    const ontouchstart = () => {
      myData.moveFlag = true
      if (myData.open) {
        myData.overflow = true
      }
    }
    const ontouchmove = (e) => {
      if (myData.moveFlag && myData.open) {
        let data = {
          x: e.touches[0].pageX,
          y: e.touches[0].pageY
        }
        myData.myUserInfo.dotArray.push(data)
        myData.canvasArray[myData.myUserInfo.userInfo.name] = {data: myData.myUserInfo}
      }
    }
    const ontouchend = () => {
      myData.moveFlag = false
      myData.overflow = false
    }
    const openSuoFang = () => {
      myData.suofang = !myData.suofang
      myData.suofangTop = 0
      myData.suofangLeft = 0
      myData.suofangRate = 1.5
      myData.open = false
      if (myData.suofang) {
        // const cvs = document.createElement('canvas')
        // const cxt = cvs.getContext('2d')
        // let img = new Image();
        // img.setAttribute('crossOrigin', 'anonymous');
        // img.src = process.env.VUE_APP_BASE_URL + '/api/Document/document/sign?key=' + myData.imgKey
        const newCanvas = document.getElementById('suoCanvas')
        newCanvas.width = myData.canvas.width
        newCanvas.height = myData.canvas.height
        // newCanvas.style.width = (myData.canvas.width / 2) + 'px'
        // newCanvas.style.height = (myData.canvas.height / 2) + 'px'
        myData.canvasImgUrl = ''
        const myCxt = newCanvas.getContext('2d')
        myCxt.scale(2, 2)
        myCxt.clearRect(0,0,myData.canvas.width, myData.canvas.height)
        for (let i in myData.canvasArray) {
          if (myData.canvasArray[i].data) {
            myCxt.beginPath()
            myCxt.strokeStyle = myData.canvasArray[i].data.userInfo.color
            if (myData.canvasArray[i].data.dotArray[0]) {
              myCxt.moveTo(
                  ((myData.canvasArray[i].data.dotArray[0].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
                  ,
                  ((myData.canvasArray[i].data.dotArray[0].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
              )
            }
            for (let j = 0; j < myData.canvasArray[i].data.dotArray.length; j++) {
              if (myData.canvasArray[i].data.dotArray[j - 1]) {
                if (Math.abs(myData.canvasArray[i].data.dotArray[j].x - myData.canvasArray[i].data.dotArray[j - 1].x) > 10 && Math.abs(myData.canvasArray[i].data.dotArray[j].y - myData.canvasArray[i].data.dotArray[j - 1].y) > 10) {
                  myCxt.moveTo(
                      ((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
                      ,
                      // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
                      ((myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
                  )
                } else {
                  myCxt.lineTo(
                      ((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
                      ,
                      // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
                      ((myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
                  )
                }
              } else {
                myCxt.lineTo(
                    ((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
                    ,
                    // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
                    ((myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
                )
              }

              // myCxt.fillRect((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width), (myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight),(4 / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width),(4 / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
            }
            // myCxt.lineWidth = 3
            myCxt.stroke();
            myCxt.closePath()
            // myData.ctx.fill();
          }
        }
        // img.onload = () => {
        //   myCxt.clearRect(0,0,myData.canvas.width, myData.canvas.height)
        //   for (let i in myData.canvasArray) {
        //     if (myData.canvasArray[i].data) {
        //       myCxt.beginPath()
        //       myCxt.strokeStyle = myData.canvasArray[i].data.userInfo.color
        //       if (myData.canvasArray[i].data.dotArray[0]) {
        //         myCxt.moveTo(
        //             ((myData.canvasArray[i].data.dotArray[0].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
        //             ,
        //             ((myData.canvasArray[i].data.dotArray[0].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
        //         )
        //       }
        //       for (let j = 0; j < myData.canvasArray[i].data.dotArray.length; j++) {
        //         if (myData.canvasArray[i].data.dotArray[j - 1]) {
        //           if (Math.abs(myData.canvasArray[i].data.dotArray[j].x - myData.canvasArray[i].data.dotArray[j - 1].x) > 10 && Math.abs(myData.canvasArray[i].data.dotArray[j].y - myData.canvasArray[i].data.dotArray[j - 1].y) > 10) {
        //             myCxt.moveTo(
        //                 ((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
        //                 ,
        //                 // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
        //                 ((myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
        //             )
        //           } else {
        //             myCxt.lineTo(
        //                 ((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
        //                 ,
        //                 // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
        //                 ((myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
        //             )
        //           }
        //         } else {
        //           myCxt.lineTo(
        //               ((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
        //               ,
        //               // myData.canvasArray[i].data.dotArray[j].y - (myData.overHeight)
        //               ((myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight))
        //           )
        //         }
        //
        //         // myCxt.fillRect((myData.canvasArray[i].data.dotArray[j].x / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width), (myData.canvasArray[i].data.dotArray[j].y / myData.canvasArray[i].data.deviceInfo.height * myData.myUserInfo.deviceInfo.height) - (2  / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width) - (myData.overHeight),(4 / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width),(4 / myData.canvasArray[i].data.deviceInfo.width * myData.myUserInfo.deviceInfo.width))
        //       }
        //       // myCxt.lineWidth = 3
        //       myCxt.stroke();
        //       myCxt.closePath()
        //       // myData.ctx.fill();
        //     }
        //   }
        //   // myData.canvasImgUrl = newCanvas.toDataURL()
        //   // newCanvas.remove()
        //   // cvs.remove()
        // }
      }
    }
    const startSuoFangFun = () => {
      const dom = document.getElementById('suoFangImg')
      // let startTouchs = {}
      // let startX1 = 0
      // let startLeft = 0
      // let startX2 = 0
      // let startY1 = 0
      // let startY2 = 0
      const suoFangImgDiv = document.getElementById('suoFangImgDiv')
      let scorllLeft = 0
      let scorllTop = 0
      dom.ontouchstart = (e) => {
        let touch1 = e.touches[0]
        let touch2 = e.touches[1]
        // startLeft = myData.suofangLeft
        // startX1 = touch1.pageX
        // startTouchs = e.touches[1]
        if (touch1 && touch2) {
          scorllLeft = suoFangImgDiv.scrollLeft
          scorllTop = suoFangImgDiv.scrollTop
          console.log(scorllLeft)
          console.log(scorllTop)
          let x1 = touch1.pageX
          // startX1 = touch1.pageX
          let x2 = touch2.pageX
          // startX2 = touch2.pageX + 100
          let y1 = touch1.pageY
          // startY1 = touch1.pageY
          let y2 = touch2.pageY
          // startY2 = touch2.pageY + 100
          myData.startDistance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
        }
      }
      dom.ontouchmove = (e) => {
        let touch1 = e.touches[0]
        let touch2 = e.touches[1]
        if (touch1 && touch2) {
          e.preventDefault()
          let x1 = touch1.pageX
          let x2 = touch2.pageX
          let y1 = touch1.pageY
          let y2 = touch2.pageY
          console.log(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
          const distance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
          // if (
          //     Math.abs(distance - myData.startDistance)  < 10 &&
          //     (Math.abs(startX1 -  x1 > 5) ||
          //         Math.abs(startX2 -  x2 > 5) ||
          //         Math.abs(startY1 -  y1 > 5) ||
          //         Math.abs(startY2 -  y2 > 5))
          // ) {
          //   console.log('平移')
          // }
          if (myData.startDistance - distance > 2) {
            myData.startDistance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
            if (myData.suofangRate > 1) {
              myData.suofangRate -= 0.01
              suoFangImgDiv.scrollLeft = suoFangImgDiv.scrollLeft - 10
              suoFangImgDiv.scrollTop = suoFangImgDiv.scrollTop - 10
            }
          }
          if (myData.startDistance - distance < -2) {
            myData.startDistance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
            myData.suofangRate += 0.01
            suoFangImgDiv.scrollLeft = suoFangImgDiv.scrollLeft + 10
            suoFangImgDiv.scrollTop = suoFangImgDiv.scrollTop + 10
          }
        }
        // else if (touch1) {
          // let x1 = touch1.pageX
          // if (dom.getBoundingClientRect().x + (x1 - startX1) <= 0 && dom.getBoundingClientRect().width + dom.getBoundingClientRect().x + (x1 - startX1) > window.screen.width) {
          //   myData.suofangLeft = startLeft + x1 - startX1
          // }
          // console.log(dom.getBoundingClientRect())
        // }
      }
    }
    const openCanvas = () => {
      if (myData.openPermission !== myData.role) {
        return
      }
      myData.open = !myData.open
      myData.suofang = false
    }
    const clearCanvas = () => {
      if (myData.openPermission !== myData.role) {
        return
      }
      myData.canvasArray[myData.myUserInfo.userInfo.name].data.dotArray = []
    }
    const openRest = () => {
      if (myData.openPermission !== myData.role) {
        return
      }
      myData.rest = !myData.rest
      _signalR.sendMessage([myData.rest], 'Common');
    }
    const openDataFun = () => {
      if (myData.openPermission !== myData.role) {
        return
      }
      myData.openData = !myData.openData
      if (myData.openData) {
        _signalR.sendMessage([], 'StartShare');
      } else {
        _signalR.sendMessage([], 'StopShare');
      }
    }
    const openonetoone = () => {
      handleJoinRoom()
    }


    // 腾讯视频
    const clientData = reactive({
      client: null,
      localStream: null,
      remoteStreamList: [],
      isJoining: false,
      isJoined: false,
      clientClick: false,
      isPublishing: false,
      checkTime: false,
      isPublished: false,
      isMutedVideo: false,
      isMutedAudio: false,
      isPlayingLocalStream: false,
    })
    const handleJoinRoom = async () => {
      if (!clientData.clientClick) {
        clientData.clientClick = true
        await initClient();
        await join();
        await initLocalStream();
        await playLocalStream();
        await publish();
        myData.nowSec = 0
        clearInterval(clientData.checkTime)
        clientData.checkTime = setInterval(() => {
          _signalR.sendMessage([('hasEnter_' + myData.role)], 'Common');
          if (document.querySelectorAll('video')[0]) {
            // myData.nowSec = parseInt(document.querySelectorAll('video')[0].currentTime)
            myData.nowSec++
          }
        }, 1000)
        clientData.clientClick = false
        myData.miniSize = false
      }
    }
    // 初始化客户端
    const  initClient = async () => {
      clientData.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: myData.sdkAppId,
        userId: myData.userId,
        userSig: myData.userSig,
      });
      handleClientEvents();
    }

    const initLocalStream = async () => {
      clientData.localStream = TRTC.createStream({
        audio: true,
        video: true,
        userId: myData.userId,
        cameraId: myData.cameraList[0] && myData.cameraList[0].deviceId,
        microphoneId: myData.microphoneList[0] && myData.microphoneList[0].deviceId
      });
      try {
        await clientData.localStream.initialize();
      } catch (error) {
        clientData.localStream = null;
        throw error;
      }
    }

    const playLocalStream = () => {
      // if (document.getElementById('localStream')) {
      //   document.getElementById('localStream').innerHTML = null
      // }
      clientData.localStream.play('localStream')
          .then(() => {
            clientData.isPlayingLocalStream = true;
          })
          .catch((error) => {
            console.log(error)
          });
    }

    const destroyLocalStream = () => {
      clientData.localStream && clientData.localStream.stop();
      clientData.localStream && clientData.localStream.close();
      clientData.localStream = null;
      clientData.isPlayingLocalStream = false;
    }

    const playRemoteStream = (remoteStream, element) => {
      console.log(element)
      // if (document.getElementById('otherStream')) {
      //   document.getElementById('otherStream').innerHTML = null
      // }
      if (remoteStream.getType() === 'main' && remoteStream.getUserId().indexOf('share') >= 0) {
        remoteStream.play('otherStream', { objectFit: 'contain' }).catch();
      } else {
        remoteStream.play('otherStream').catch();
      }
    }

    const resumeStream = (stream) => {
      stream.resume();
    }

    const join = async () => {
      if (clientData.isJoining || clientData.isJoined) {
        return;
      }
      clientData.isJoining = true;
      !clientData.client && await initClient();
      try {
        await clientData.client.join({ roomId: myData.roomId });
        clientData.isJoining = false;
        clientData.isJoined = true;
        startGetAudioLevel();
      } catch (error) {
        clientData.isJoining = false;
        console.error('join room failed', error);
        throw error;
      }
    }

    const publish = async () => {
      if (!clientData.isJoined || clientData.isPublishing || clientData.isPublished) {
        return;
      }
      clientData.isPublishing = true;
      try {
        await clientData.client.publish(clientData.localStream);
        clientData.isPublishing = false;
        clientData.isPublished = true;
      } catch (error) {
        clientData.isPublishing = false;
        console.error('publish localStream failed', error);
        throw error;
      }
    }

    const unPublish = async () => {
      if (!clientData.isPublished || clientData.isUnPublishing) {
        return;
      }
      clientData.isUnPublishing = true;
      try {
        await clientData.client.unpublish(clientData.localStream);
        clientData.isUnPublishing = false;
        clientData.isPublished = false;
      } catch (error) {
        clientData.isUnPublishing = false;
        console.error('unpublish localStream failed', error);
        throw error;
      }
    }

    const subscribe = async (remoteStream, config = { audio: true, video: true }) => {
      try {
        await clientData.client.subscribe(remoteStream, {
          audio: isUndefined(config.audio) ? true : config.audio,
          video: isUndefined(config.video) ? true : config.video,
        });
      } catch (error) {
        clientData.reportFailedEvent('subscribe', error);
      }
    }

    const unSubscribe = async (remoteStream) => {
      try {
        await clientData.client.unsubscribe(remoteStream);
      } catch (error) {
        console.error(`unsubscribe ${remoteStream.getUserId()} error`, error);
      }
    }

    const leave = async () => {
      if (!clientData.isJoined || clientData.isLeaving) {
        return;
      }
      clientData.isLeaving = true;
      stopGetAudioLevel();
      clientData.isPublished && await unPublish();
      clientData.localStream && destroyLocalStream();

      try {
        await clientData.client.leave();
        clientData.isLeaving = false;
        clientData.isJoined = false;
      } catch (error) {
        clientData.isLeaving = false;
        console.error('leave room error', error);
        throw error;
      }
    }

    const muteVideo = () => {
      if (clientData.localStream) {
        clientData.localStream.muteVideo();
        clientData.isMutedVideo = true;
      }
    }

    const muteAudio = () => {
      if (clientData.localStream) {
        clientData.localStream.muteAudio();
        clientData.isMutedAudio = true;
      }
    }

    const unmuteVideo = () => {
      if (clientData.localStream) {
        clientData.localStream.unmuteVideo();
        clientData.isMutedVideo = false;
      }
    }

    const unmuteAudio = () => {
      if (clientData.localStream) {
        clientData.localStream.unmuteAudio();
        clientData.isMutedAudio = false;
      }
    }

    const switchDeviceFun = async () => {
      if (myData.cameraList.length > 1) {
        if (myData.nowCamera === 0) {
          await clientData.localStream.switchDevice('video', myData.cameraList[myData.cameraList.length - 1].deviceId)
          myData.nowCamera = 1
        } else {
          await clientData.localStream.switchDevice('video', myData.cameraList[0].deviceId)
          myData.nowCamera = 0
        }
      }
      console.log(myData.cameraList)
    }

    const switchDevice = (type, deviceId) => {
      try {
        if (clientData.localStream) {
          clientData.localStream.switchDevice(type, deviceId);
        }
      } catch (error) {
        console.error('switchDevice failed', error);
      }
    }

    const startGetAudioLevel = () => {
      // 文档：https://web.sdk.qcloud.com/trtc/webrtc/doc/zh-cn/module-ClientEvent.html#.AUDIO_VOLUME
      clientData.client.on('audio-volume', (event) => {
        event.result.forEach(({ userId, audioVolume }) => {
          if (audioVolume > 2) {
            console.log(`user: ${userId} is speaking, audioVolume: ${audioVolume}`);
          }
        });
      });
      clientData.client.enableAudioVolumeEvaluation(200);
    }

    const stopGetAudioLevel = () => {
      clientData.client && clientData.client.enableAudioVolumeEvaluation(-1);
    }
    const closeClient = async () => {
      // clientData.isPublished = false;
      // clientData.localStream = null;
      proxy.$commonJs.doubleComfirm({
        content: '是否确定挂断？',
        successFun: async () => {
          await leave();
        },
        errorFun: () => {
        }
      })
    }
    const closeVFun = () => {
      if (clientData.isMutedVideo) {
        unmuteVideo()
      } else {
        muteVideo()
      }
    }
    const closeAFun = () => {
      if (clientData.isMutedAudio) {
        unmuteAudio()
      } else {
        muteAudio()
      }
    }
    const showMiniSize = () => {
      myData.miniSize = true
      myData.moveDomData.startX = 0
      myData.moveDomData.startY = 0
      myData.moveDomData.left = 0
      myData.moveDomData.top = 0
      // nextTick(() => {
      //   if (document.querySelectorAll('.smallSize')[0]) {
      //     const moveDom = document.querySelectorAll('.smallSize')[0]
      //     moveDom.ontouchstart = (e) => {
      //       if (myData.miniSize) {
      //         myData.nowX = e.touches[0].pageX
      //         myData.nowY = e.touches[0].pageY
      //         myData.moveDomData.startX = myData.moveDomData.left
      //         myData.moveDomData.startY = myData.moveDomData.top
      //       }
      //     }
      //     moveDom.ontouchmove = (e) => {
      //       e.preventDefault()
      //       if (myData.miniSize) {
      //         myData.moveDomData.left = myData.moveDomData.startX + (e.touches[0].pageX - myData.nowX)
      //         myData.moveDomData.top = myData.moveDomData.startY + (e.touches[0].pageY - myData.nowY)
      //         moveDom.style.transform = 'translate(' + myData.moveDomData.left + 'px, ' + myData.moveDomData.top + 'px)'
      //       }
      //     }
      //     moveDom.ontouchend = () => {
      //     }
      //   }
      // })
    }
    const unTrans = () => {
      document.getElementById('localStream').style.transform = null
      document.getElementById('otherStream').style.transform = null
    }

    const changeToAudio = () => {
      const videoTrack = clientData.localStream.getVideoTrack();
      if (videoTrack) {
        clientData.localStream.removeTrack(videoTrack).then(() => {
          // 关闭视频通话成功，停止videoTrack并释放摄像头资源
          videoTrack.stop();
        });
      }
    }
    const syncScorll = (e) => {
      console.log(e)
    }
    const handleClientEvents = () => {
      clientData.client.on('error', (error) => {
        console.error(error);
      });
      clientData.client.on('client-banned', async (event) => {
        console.warn(`client has been banned for ${event.reason}`);

        clientData.isPublished = false;
        clientData.localStream = null;
        await leave();
      });
      // fired when a remote peer is joining the room
      clientData.client.on('peer-join', (event) => {
        const { userId } = event;
        console.log(`peer-join ${userId}`, event);
      });
      // fired when a remote peer is leaving the room
      clientData.client.on('peer-leave', (event) => {
        const { userId } = event;
        console.log(`peer-leave ${userId}`, event);
      });

      // fired when a remote stream is added
      clientData.client.on('stream-added', (event) => {
        const { stream: remoteStream } = event;
        const remoteUserId = remoteStream.getUserId();
        if (remoteUserId === `share_${myData.userId}`) {
          // don't need screen shared by us
          unSubscribe(remoteStream);
        } else {
          console.log(`remote stream added: [${remoteUserId}] type: ${remoteStream.getType()}`);
          // subscribe to this remote stream
          subscribe(remoteStream);
        }
      });
      // fired when a remote stream has been subscribed
      clientData.client.on('stream-subscribed', (event) => {
        const { stream: remoteStream } = event;
        const remoteUserId = remoteStream.getUserId();
        console.log('stream-subscribed userId: ', remoteUserId);
        clientData.remoteStreamList.push(remoteStream);
        nextTick(() => {
          playRemoteStream(remoteStream, remoteUserId);
        });
      });
      // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
      clientData.client.on('stream-removed', (event) => {
        const { stream: remoteStream } = event;
        remoteStream.stop();
        const index = clientData.remoteStreamList.indexOf(remoteStream);
        if (index >= 0) {
          clientData.remoteStreamList.splice(index, 1);
        }
        console.log(`stream-removed userId: ${remoteStream.getUserId()} type: ${remoteStream.getType()}`);
      });

      clientData.client.on('stream-updated', (event) => {
        const { stream: remoteStream } = event;
        console.log(`type: ${remoteStream.getType()} stream-updated hasAudio: ${remoteStream.hasAudio()} hasVideo: ${remoteStream.hasVideo()}`);
      });

      clientData.client.on('mute-audio', (event) => {
        const { userId } = event;
        console.log(`${userId} mute audio`);
      });
      clientData.client.on('unmute-audio', (event) => {
        const { userId } = event;
        console.log(`${userId} unmute audio`);
      });
      clientData.client.on('mute-video', (event) => {
        const { userId } = event;
        console.log(`${userId} mute video`);
      });
      clientData.client.on('unmute-video', (event) => {
        const { userId } = event;
        console.log(`${userId} unmute video`);
      });

      clientData.client.on('connection-state-changed', (event) => {
        console.log(`RtcClient state changed to ${event.state} from ${event.prevState}`);
      });

      clientData.client.on('network-quality', (event) => {
        const { uplinkNetworkQuality, downlinkNetworkQuality } = event;
        console.log(`network-quality uplinkNetworkQuality: ${uplinkNetworkQuality}, downlinkNetworkQuality: ${downlinkNetworkQuality}`);
      });
    }
    return {
      ...toRefs(myData),
      ...toRefs(clientData),
      openPermissionFun,
      openonetoone,
      closeVFun,
      changeToAudio,
      unTrans,
      closeAFun,
      showMiniSize,
      formatSeconds,
      changeType,
      nextDocument,
      setCanvas,
      ontouchmove,
      openCanvas,
      openSuoFang,
      openRest,
      startSuoFangFun,
      muteVideo,
      muteAudio,
      unmuteAudio,
      switchDevice,
      switchDeviceFun,
      unmuteVideo,
      clearCanvas,
      syncScorll,
      resumeStream,
      ontouchend,
      openDataFun,
      ontouchstart,
      closeClient,
      imageLoad,
      startSuoFang,
      changePage,
      selectionAll,
      prevDocument
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.callBack {
  width: 50%;
  margin-top: 100px;
  left: 25%;
}
.nickName {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  color: #333;
  font-size: 12px;
}
.callBack1 {
  width: 125px;
  height: 125px;
  border-radius: 100px;
  position: absolute;
  top: 15%;
  left: 15%;
  z-index: 1;
}
.callBack1 img{
  border-radius: 100px;
}
.callBack2 img{
  border-radius: 100px;
}
.callBack1 .card{
  position: absolute;
  bottom: 10px;
  padding: 5px 20px;
  left: calc(50% - 30px);
  color: #fff;
  font-size: 12px;
  background-color: #FFB703;
  border-radius: 4px;
}
.callBack2 .card{
  position: absolute;
  bottom: 10px;
  padding: 5px 20px;
  left: calc(50% - 30px);
  color: #fff;
  font-size: 12px;
  background-color: #FFB703;
  border-radius: 4px;
}
.callBack2 {
  width: 125px;
  height: 125px;
  border-radius: 100px;
  position: absolute;
  top: 70%;
  right: 15%;
  z-index: 1;
}
.bottomZiliaoNew {
  position: fixed;
  bottom: 120px;
  width: 90%;
  left: 5%;
  height: 50px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  display: flex;
  z-index: 100;
  align-items: center;
}

.bottomZiliaoNew > div:nth-child(1){
  width: calc(95% - 60px);
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-radius: 5px;
  background-color: #262932;
  padding: 5px 0;
  height: 100%;
}
.bottomZiliaoNew > div:nth-child(1) img{
  margin-bottom: 10px;
  width: 50%;
}
.bottomZiliaoNew > div:nth-child(2){
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  border-radius: 5px;
  background-color: #262932;
  padding: 5px 0;
  height: 100%;
}

.bottomZiliao {
  position: fixed;
  bottom: 120px;
  width: 90%;
  left: 5%;
  height: 50px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  display: flex;
  z-index: 99;
  align-items: center;
}

.bottomZiliao > div:nth-child(1){
  width: 65%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-radius: 5px;
  background-color: #262932;
  padding: 5px 0;
  height: 100%;
}
.bottomZiliao > div:nth-child(1) img{
  margin-bottom: 10px;
  width: 50%;
}
.bottomZiliao > div:nth-child(2){
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  border-radius: 5px;
  background-color: #262932;
  padding: 5px 0;
  height: 100%;
}
.ziliaoPlace {
  position: relative;
  z-index: 1;
  margin: 0 20px;
  padding-top: 85px;
  height: 75px;
  background-color: #fff;
  border-radius: 10px;
}
.ziliaoPlace img{
  border-radius: 10px 0 0 10px;
  width: 75px;
  height: 75px;
}
.ziliaoPlace > div {
  background-color: #F7F7F7;
  display: flex;
  align-items: center;
}
.greenTip {
  width: 12px;
  height: 12px;
  background-color: limegreen;
  border-radius: 20px;
  position: absolute;
  top: -4px;
  right: -4px;
}
.newGreenTip{
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  top: -4px;
  right: 2px;
}
</style>

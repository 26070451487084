<template>
  <section @click="stopShow($event)" id="cander">
    <section class="canderInput" :style="{'width' : domWidth}">
      <section class="canderInputPlace">
        <input :disabled="newDisabled" readonly="readonly" @focus="showCanderFun()" @blur="hiddenChoose($event)" v-model="fatherDate" :class="className" type="text" :placeholder="placeholderName" style="width: 100%">
        <b v-if="!newDisabled" @click="removeValue">X</b>
      </section>
      <section :id="randomId" class="canderDiv">
        <section class="canderHeader">
          <span @click="prevYear()"><b style="margin-right: -5px">＜</b><b>＜</b></span>
          <span @click="prevMonth()"><b>＜ </b></span>
          <span class="headerTitle"><b>{{nowYear}}年{{nowMonth}}月</b></span>
          <span @click="nextMonth()"><b>＞</b></span>
          <span @click="nextYear()"><b style="margin-right: -5px">＞</b><b>＞</b></span>
        </section>
        <section class="canderBody">
          <section><span>日</span><span>一</span><span>二</span><span>三</span><span>四</span><span>五</span><span>六</span>
          </section>
          <section class="canderBodyDate">
            <span @click="chooseDate(item)"  :style="{'color': item.isLast? '#eee' : '' ,'background' :  item.disabled? '#f3f3f3' : '' }" :class="returnChooseClass(item)" :key="index" v-for="(item, index) in dataTimeList">{{item.nowNum}}</span>
          </section>
          <section class="showTimePlace" v-if="showTimePicker">
            <select @change="changeChooseDate" v-model="newHour" class="canderHour">
              <option value="00">00</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
            </select>
            <select @change="changeChooseDate" v-model="newMinite" class="canderMin">
              <option value="00">00</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
            </select>
            <select @change="changeChooseDate" v-model="newDate" class="canderDate">
              <option value="00">00</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
            </select>
          </section>
          <section class="confirmButton" style="padding: 10px 0" v-if="showTimePicker">
            <button @click="chooseDateConfirm" class="btn btn_success"><abbr class="font12">确定</abbr></button>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'cander',
  model: {
    prop: 'fatherDate',
    event: 'fatherDate'
  },
  props: [
    'needReplaceToday',
    'minDate',
    'className',
    'showTimePicker',
    'placeholderName',
    'newWidth',
    'disabled',
    'canNotEqure'
  ],
  data () {
    return {
      chooseNowDate: '',
      chooseSelectDate: '',
      domWidth: this.newWidth,
      newDisabled: this.disabled,
      newMinDate: this.minDate,
      randomId: 'cander' + new Date().getTime() + Math.random() * 1000,
      showCander: false,
      nowYear: 2019,
      nowMonth: 1,
      nowDate: new Date(),
      dataTimeList: [],
      newHour: '00',
      newMinite: '00',
      newDate: '00'
    }
  },
  watch: {
    newWidth (value) {
      this.domWidth = value
    },
    disabled (value) {
      this.newDisabled = value
    },
    minDate (value) {
      this.newMinDate = value
      console.log(this.nowDate)
      if (this.nowDate) {
        let year = this.nowDate.getFullYear()
        let month = this.nowDate.getMonth() + 1
        const date = this.nowDate.getDate()
        this.replaceNewDate(year + '-' + month + '-' + date)
        if (this.fatherDate !== '' && this.fatherDate !== undefined && this.fatherDate !== null) {
          if (new Date(this.fatherDate.replace(/-/g, '/')).getTime() < new Date(this.newMinDate.replace(/-/g, '/')).getTime()) {
            this.$emit('fatherDate', '')
          }
        }
      }
    }
  },
  created () { // vue 页面的生命周期 当我一进来页面之后 我先执行
    this.replaceNewDate()
    console.log(this.newWidth)
  },
  mounted () { // vue 页面的生命周期 当我进来之后 且加载完资源了之后再执行
    document.getElementById('index').onclick = function () {
      let canderList = document.querySelectorAll('.canderDiv')
      for (let i = 0; i < canderList.length; i++) {
        canderList[i].style.display = 'none'
      }
    }
  },
  methods: { // 所有的方法封装
    chooseDateConfirm () {
      if (this.chooseSelectDate) {
        if (this.minDate) {
          console.log(this.chooseSelectDate)
          if (new Date(this.minDate.replace(/-/g, '/')).getTime() < new Date().getTime() && new Date(this.chooseSelectDate.replace(/-/g, '/')).getTime() < new Date().getTime() && new Date(this.chooseSelectDate.replace(/-/g, '/')).getTime() > new Date(this.minDate.replace(/-/g, '/')).getTime()) {
            if (this.needReplaceToday) {
              this.$emit('fatherDate', this.$commonJs.todayDate + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate)
              document.getElementById(this.randomId).style.display = 'none'
              return
            }
          } else {
            if (this.canNotEqure) {
              if (new Date(this.minDate.replace(/-/g, '/')).getTime() > new Date(this.chooseSelectDate.replace(/-/g, '/')).getTime()) {
                this.$commonJs.alertTips({
                  type: 'warning',
                  title: '提示',
                  content: '结束时间不能小于开始时间',
                  stayTime: 5
                })
                return
              }
            } else {
              if (new Date(this.minDate.replace(/-/g, '/')).getTime() >= new Date(this.chooseSelectDate.replace(/-/g, '/')).getTime()) {
                this.$commonJs.alertTips({
                  type: 'warning',
                  title: '提示',
                  content: '结束时间不能小于开始时间',
                  stayTime: 5
                })
                return
              }
            }
          }
        }
        this.$emit('fatherDate', this.chooseSelectDate)
        document.getElementById(this.randomId).style.display = 'none'
      } else {
        let year
        let month
        let date
        if (this.newMinDate) {
          year = new Date(this.newMinDate).getFullYear()
          month = new Date(this.newMinDate).getMonth() + 1
          date = new Date(this.newMinDate).getDate()
        } else {
          year = this.nowDate.getFullYear()
          month = this.nowDate.getMonth() + 1
          date = this.nowDate.getDate()
        }
        let now = year + '-' + month + '-' + date + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate
        if (this.minDate) {
          if (new Date(this.minDate.replace(/-/g, '/')).getTime() < new Date().getTime()) {
            this.$emit('fatherDate', this.$commonJs.todayDate + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate)
            document.getElementById(this.randomId).style.display = 'none'
            return
          } else {
            if (this.canNotEqure) {
              if (new Date(this.minDate.replace(/-/g, '/')).getTime() > new Date(now.replace(/-/g, '/')).getTime()) {
                this.$commonJs.alertTips({
                  type: 'warning',
                  title: '提示',
                  content: '结束时间不能小于开始时间',
                  stayTime: 5
                })
                return
              }
            } else {
              if (new Date(this.minDate.replace(/-/g, '/')).getTime() >= new Date(now.replace(/-/g, '/')).getTime()) {
                this.$commonJs.alertTips({
                  type: 'warning',
                  title: '提示',
                  content: '结束时间不能小于开始时间',
                  stayTime: 5
                })
                return
              }
            }
          }
        }
        this.$emit('fatherDate', year + '-' + month + '-' + date + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate)
        document.getElementById(this.randomId).style.display = 'none'
      }
    },
    returnChooseClass (item) {
      if (item.isNow && item.isChoose) {
        return 'chooseDay'
      } else {
        if (item.isNow) {
          return 'nowDay'
        } else if (item.isChoose) {
          return 'chooseDay'
        }
      }
    },
    showCanderFun () {
      document.getElementById(this.randomId).style.display = 'block'
    },
    removeValue () {
      this.$emit('fatherDate', '')
    },
    hiddenChoose () {
      // const that = this
      // setTimeout(function () {
      //   that.showCander = false
      // }, 100)
    },
    stopShow (e) {
      e.stopPropagation()
    },
    chooseDate (item) {
      if (!item.disabled) {
        if (this.showTimePicker) {
          for (let i = 0; i < this.dataTimeList.length; i++) {
            if (this.dataTimeList[i].isChoose) {
              this.dataTimeList[i].isChoose = false
            }
          }
          this.$set(item, 'isChoose', true)
          this.chooseNowDate = item.nowDate
          this.chooseSelectDate = this.chooseNowDate + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate
          // this.replaceNewDate()
          console.log(item)
        } else {
          let nowDate = item.nowDate
          this.$emit('fatherDate', nowDate)
          this.$emit('chooseComplete', nowDate)
          document.getElementById(this.randomId).style.display = 'none'
        }
      }
    },
    changeChooseDate () {
      if (this.chooseNowDate) {
        this.chooseSelectDate = this.chooseNowDate + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate
      } else {
        let year
        let month
        let date
        if (this.newMinDate) {
          year = new Date(this.newMinDate).getFullYear()
          month = new Date(this.newMinDate).getMonth() + 1
          date = new Date(this.newMinDate).getDate()
        } else {
          year = this.nowDate.getFullYear()
          month = this.nowDate.getMonth() + 1
          date = this.nowDate.getDate()
        }
        this.chooseSelectDate = year + '-' + month + '-' + date + ' ' + this.newHour + ':' + this.newMinite + ':' + this.newDate
      }
    },
    prevMonth () {
      let year = this.nowDate.getFullYear()
      let month = this.nowDate.getMonth()
      month = month === 0 ? 12 : month
      year = month === 12 ? year - 1 : year
      const date = this.nowDate.getDate()
      this.replaceNewDate(year + '-' + month + '-' + date)
    },
    nextMonth () {
      let year = this.nowDate.getFullYear()
      let month = this.nowDate.getMonth() + 2
      month = month === 13 ? 1 : month
      year = month === 1 ? year + 1 : year
      const date = this.nowDate.getDate()
      this.replaceNewDate(year + '-' + month + '-' + date)
    },
    prevYear () {
      const year = this.nowDate.getFullYear() - 1
      const month = this.nowDate.getMonth() + 1
      const date = this.nowDate.getDate()
      this.replaceNewDate(year + '-' + month + '-' + date)
    },
    nextYear () {
      const year = this.nowDate.getFullYear() + 1
      const month = this.nowDate.getMonth() + 1
      const date = this.nowDate.getDate()
      this.replaceNewDate(year + '-' + month + '-' + date)
    },
    replaceNewDate (date) {
      this.chooseNowDate = ''
      this.dataTimeList = []
      let nowDate = new Date()
      if (date) {
        nowDate = new Date(date.replace(/-/g, '/'))
      }
      this.nowDate = nowDate
      const year = nowDate.getFullYear()
      const month = nowDate.getMonth() + 1
      // const day = nowDate.getDate()
      this.nowYear = year
      this.nowMonth = month < 10 ? '0' + month : month
      const week = new Date(year + '/' + month + '/' + 1).getDay()
      let num = 1
      let lastNum = 1
      console.log(this.returnMaxMonth(year, month))
      for (let i = 0; i < 42; i++) {
        let data = {
          nowNum: '',
          nowDate: '',
          isNow: false,
          isLast: false,
          disabled: false
        }
        if (i < week) {
          data.isLast = true
          if (month === 1) {
            data.nowDate = (year - 1) + '-' + 12 + '-' + (this.returnMaxMonth(year - 1, 12) - week + i + 1)
            data.nowNum = this.returnMaxMonth(year - 1, 12) - week + i + 1
          } else {
            data.nowDate = year + '-' + (month - 1) + '-' + (this.returnMaxMonth(year, month - 1) - week + i + 1)
            data.nowNum = this.returnMaxMonth(year, month - 1) - week + i + 1
          }
        } else if (num > this.returnMaxMonth(year, month)) {
          data.isLast = true
          if (month === 12) {
            data.nowDate = (year + 1) + '-' + 1 + '-' + lastNum
            data.nowNum = lastNum
          } else {
            data.nowDate = year + '-' + (month + 1) + '-' + lastNum
            data.nowNum = lastNum
          }
          lastNum++
        } else {
          data.nowDate = year + '-' + month + '-' + num
          data.nowNum = num
          if (new Date().getFullYear() === year && new Date().getMonth() + 1 === month && new Date().getDate() === num) {
            data.isNow = true
          }
          num++
        }
        if (this.newMinDate) {
          if (new Date(data.nowDate.replace(/-/g, '/')).getTime() < new Date(this.newMinDate.replace(/-/g, '/')).getTime() - (24 * 60 * 60 * 1000) + 1) {
            data.disabled = true
          }
        }
        this.dataTimeList.push(data)
      }
    },
    returnMaxMonth (year, month) {
      if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) >= 0) {
        return 31
      }
      if ([4, 6, 9, 11].indexOf(month) >= 0) {
        return 30
      }
      if (month === 2) {
        if (year % 4 === 0) {
          return 29
        } else {
          return 28
        }
      }
    }
  },
  components: {
  }
}
</script>

<style scoped>
  .canderInput{
    position: relative;
    width: 100%;
  }
  .canderInput .canderInputPlace:hover b{
    display: inline-block;
  }
  .canderInput .canderInputPlace{
    display: flex;
    align-items: center;
  }
  .canderInput .canderInputPlace b{
    position: absolute;
    right: 10px;
    cursor: pointer;
    display: none;
  }
  .canderDiv{
    display: none;
    position: absolute;
    z-index: 999;
    width: 210px;
    background: #fff;
    border:1px solid #eee;
  }
  .canderHeader{
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid #f1f1f1;
    white-space: nowrap;
  }
  .canderHeader span{
    margin: 0px 6px;
    cursor: pointer;
    color: #666;
  }
  .canderHeader span b{
    font-family: "微软雅黑", "Arial";
    font-size: 8px;
  }
  .canderBody{
    text-align: center;
  }
  .canderBody span{
    display: inline-block;
    width: 23px;
    margin: 2px;
    height: 23px;
    line-height: 23px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .canderBodyDate{
    white-space: normal;
  }
  .canderBodyDate span.nowDay {
    background: #d2e9f3;
    color: #fff;
  }
  .canderBodyDate span.chooseDay {
    background: #FF8144!important;
    color: #fff;
  }
  .canderBodyDate span:disabled{
    background: #eee!important;
  }
  .canderBody .canderBodyDate span:hover{
    background: #408DEB;
    color: #fff;
  }
  .showTimePlace{
    margin: 10px 5px;
  }
  .showTimePlace select{
    width: 30%;
    border: 1px solid #eee;
  }
</style>
